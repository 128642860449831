<template>
  <HXContentCard title="Lista de Personas">
    <div class="text-right px-4 mt-3">
      <button v-b-tooltip.hover title="Gestión cuentas de cobros" class="mx-1 btn btn-success"
              @click="$router.push({ name: 'AuditoriaCuentaCobro' })">
        <i class="fas fa-file-circle-check"></i>
        Gestión CDC
      </button>
      <button class="mx-1 btn btn-primary" @click="$router.push({ name: 'Persona_agregar' })">
        <i class="fas fa-plus-circle"></i>
        Agregar Persona
      </button>
    </div>
    <div class="px-5 py-3">
      <CardTable :data="dataCards"/>
    </div>
    <div class="table-responsive px-4 mt-3">
      <div class='col-12 col-md-4 pb-3' v-if="persona.rol.nivel === 4">
        <label for="Nombre">Empresa *</label>
        <b-form-select name='Impuesto' v-model="EmpresaSeleccionada" :options='EmpresasLista' :value-field="'ID'"
                       :text-field="'Nombre'" @change="$refs.dataTableLotes.$emit('actualizar');"
                       placeholder='-- Seleccionar una empresa --' required>
          <template #selected="{ option }">
            {{ option?.Impuesto || '-- Seleccionar una empresa --' }}
          </template>
          <template #first>
            <b-form-select-option value="null">-- Seleccionar una empresa --</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns" :busqueda-boton="true">
        <template v-slot:Estado="{ row }">
          <div class="d-flex justify-content-center align-content-center">
            <VueToggles height="20" width="50" :value="!!row.Estado" @click="TogglePersonaEstado(row.Id)"/>
          </div>
        </template>
        <template v-slot:Detalles="{ row }">
          <b-button v-b-modal.modal-persona @click="infoForPerson(row)">
            <i class="fas fa-eye mx-auto"></i>
          </b-button>
        </template>
        <template v-slot:Id="{ row }">
          <div class="d-flex justify-content-center px-0 mx-0">
            <vs-button v-b-tooltip="{
              title: `Ingreso / Retiro`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" style="background-color: #f4c000" warn icon v-b-modal.modal-retiro @click="infoForPerson(row)">
              <i class="fas fa-solid fa-check"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Registro novedades`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" style="background-color: #6f8ac4" success icon @click="gotoRegistroNovedades(row)">
              <i class="fas fa-solid fa-check"></i>
            </vs-button>
            <vs-button danger v-b-tooltip="{
              title: `Cargar soportes CDC`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" icon @click="$router.push({ name: 'AgregarArchivoCuentaCobro', params: { id: row.Id } })">
              <!-- :to="{ name: 'AgregarArchivoCuentaCobro/' + item.id }" -->
              <i class="fas fa-file-arrow-up"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Hoja de vida`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" icon @click="$router.push({ name: 'hoja_vida', params: { id: row.Id } })">
              <i class="fas fa-solid fa-file"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `historia laboral`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" warn icon @click="
              $router.push({
                name: 'Persona_historial_laboral',
                params: { id: row.Id },
              })
              ">
              <i class="fas fa-solid fa-clock"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Editar`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" success icon @click="
              $router.push({
                name: 'Persona_editar',
                params: { id: row.Id },
              })
              ">
              <i class="fas fa-solid fa-pen"></i>
            </vs-button>
            <vs-button v-b-tooltip="{
              title: `Gestionar accesos`,
              placement: 'top',
              customClass: 'font_tooltip',
            }" v-if="persona.rol.nivel === 4 || persona.rol.nivel === 2" icon danger @click="
              $router.push({
                name: 'ControlAccesosPersona',
                params: { PersonaId: row.Id },
              })
              ">
              <i class="fas fa-solid fa-lock"></i>
            </vs-button>
          </div>
        </template>
      </DataTableLotes>
    </div>

    <b-modal id="modal-persona" title="Detalles Persona" size="lg" hide-footer>
      <div class="row">
        <div class="col-12">
          <h3>
            <strong> Informacion Personal </strong>
          </h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span>
              <strong> Nombres </strong> <br/>
              {{
                infoForPersonData.nombres + " " + (infoForPersonData.segundoNombre != null ?
                    infoForPersonData.segundoNombre :
                    "")
              }}
            </span>
            <span>
              <strong> Apellidos </strong> <br/>
              {{
                infoForPersonData.apellidos + " " + (infoForPersonData.segundoApellido != null ?
                    infoForPersonData.segundoApellido : "")
              }}
            </span>
            <span>
              <strong>Tipo de Documento</strong> <br/>
              {{ infoForPersonData.tipoDoc }}</span>
            <span>
              <strong> Numero de Documento </strong> <br/>
              {{ infoForPersonData.doc }}</span>
            <span>
              <strong> Estado Civil </strong> <br/>
              {{ infoForPersonData.estadoCivil }}</span>
          </div>
        </div>

        <div class="col-12 mt-2">
          <h3>
            <strong> Contacto </strong>
          </h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span>
              <strong> Direccion </strong> <br/>
              {{ infoForPersonData.direccion }}</span>
            <span>
              <strong> Municipio </strong> <br/>
              {{ infoForPersonData.municipio }}</span>
            <span>
              <strong> Telefono Fijo </strong> <br/>
              {{ infoForPersonData.telFijo }}</span>
            <span>
              <strong>Telefono Movil</strong> <br/>
              {{ infoForPersonData.telMovil }}</span>
          </div>
        </div>
        <div class="col-12 mt-2">
          <h3><strong>Seguridad</strong></h3>
          <span class="mt-2">
            <strong> Correo </strong> <br/>
            {{ infoForPersonData.correo }}</span>
        </div>

        <div class="col-12 mt-2">
          <h3><strong> Empresa </strong></h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span><strong> Rol en Empresa </strong> <br/>
              {{ infoForPersonData.rol }}</span>
            <span>
              <strong> Empresa </strong> <br/>
              {{ infoForPersonData.empresa }}</span>
            <span> <strong> Sucursal </strong> <br/>{{ infoForPersonData.sucursal }}</span>
            <span>
              <strong> Area </strong> <br/>
              {{ infoForPersonData.area }}</span>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- modal ingreso retiro -->
    <b-modal id="modal-retiro" title="Ingreso / Retiro" size="md" hide-footer>
      <form ref="formIngresoRetiro" @submit.prevent="guardarIngresoRetiro()">
        <div>
          <strong> Usuario </strong>
          <br/>
          <span>
            {{ infoForPersonData.nombres }}
            {{ infoForPersonData.segundoNombre ?? "" }}
            {{ infoForPersonData.apellidos }}
            {{ infoForPersonData.segundoApellido ?? "" }}
          </span>
        </div>
        <div class="row" v-for="itn of NPerFechaIngRet" :key="itn.id">
          <div class="col-12 bg-dark" style="border-radius: 10px" v-if="itn.tipo === 'Ingreso'">
            <div class="d-flex pt-2 px-1">
              <i class="fa-solid fa-calendar-plus h3"></i>
              <div class="pl-2">
                <span class="pb-0 mb-0 text-light mt-3" style="font-size: 13px">Fecha contratación:</span>
                <b class="">
                  {{ $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY") }}
                </b>
              </div>
            </div>
          </div>
          <div class="col-12 bg-dark mt-2" style="border-radius: 10px" v-if="itn.tipo != 'Ingreso'">
            <div class="d-flex pt-2 px-1">
              <i class="fa-regular fa-calendar-minus h3"></i>
              <div class="pl-2">
                <span class="pb-0 mb-0 text-light" style="font-size: 13px">Fecha retiro:</span>
                <b v-if="infoForPersonData.fechaRetiro != null" class=" ">
                  {{ $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY") }}
                </b>
                <b v-else class=" "> -- -- -- </b>
                <div class="d-flex">
                  <div data-v-6fc0398c="" class="my-1 mx-1"
                       style="width: 7px; height: 7px; border-radius: 50%; background-color: red"></div>
                  <span class="d-flex">{{
                      listaCausalRetiro.filter((x) => (x.id = itn.perCausalRetiroId))[0].causal
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="infoForPersonData.fechaRetiro == null" class="mt-2">
          <label class="m-0 pl-1 pb-1" for="">Causal de retiro</label>
          <b-form-select required v-model="selected" value-field="id" text-field="causal" :options="listaCausalRetiro">
            <template #first>
              <b-form-select-option selected :value="null" disabled>-- selecciona una causal --</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <vs-button type="submit" primary icon class="px-2">
            <i class="fa-solid fa-floppy-disk mr-1"></i>
            Guardar
          </vs-button>
        </div>
      </form>
    </b-modal>
  </HXContentCard>
</template>
<script>
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";
import CardTable from "@/components/cardTable/CardTableV2.vue";

const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  components: {CardTable, DataTableLotes},
  data() {
    return {
      dataCards: [
        {
          title: "Todos",
          value: 0,
          iconName: "globe",
          color: "rgba(182,98,255,0.64)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            this.TipoFiltro = "All";
            this.$refs.dataTableLotes.$emit('actualizar');
          },
        },
        {
          title: "Activos",
          value: 0,
          iconName: "plus-circle",
          color: "rgba(21, 128, 61, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            this.TipoFiltro = "Activo";
            this.$refs.dataTableLotes.$emit('actualizar');
          },
        },
        {
          title: "Inactivos",
          value: 0,
          iconName: "ban",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            this.TipoFiltro = "Inactivo";
            this.$refs.dataTableLotes.$emit('actualizar');
          }
        }
      ],
      activeCard: 0,
      options: [
        {id: 1, value: null, text: "Please select an option"},
        {id: 2, value: "a", text: "This is First option"},
        {id: 3, value: "b", text: "Selected Option"},
        {
          id: 4,
          value: {C: "3PO"},
          text: "This is an option with object value",
        },
        {id: 5, value: "d", text: "This one is disabled", disabled: true},
      ],
      tableName: "Persona",
      listaItems: [],
      persona: {},
      empresaId: "",
      listaPersonaUbicacion: [],
      infoForPersonData: {
        nombres: null,
        segundoNombre: null,
        apellidos: null,
        segundoApellido: null,
        tipoDoc: null,
        doc: null,
        estadoCivil: null,
        direccion: null,
        municipio: null,
        telFijo: null,
        telMovil: null,
        correo: null,
        empresa: null,
        rol: null,
        sucursal: null,
        area: null,
      },
      dataIngresoRetiro: {},
      selected: null,
      listaCausalRetiro: [],
      NPerFechaIngRet: [],
      columns: [
        {label: "Numero Documento", key: "NumeroDocumento", sortable: true, width: '100px'},
        {label: "Nombre", key: "NombreCompleto", sortable: true, width: '300px'},
        {label: "Contraseña reportes", key: "ContrasenaReportes", sortable: true, width: '100px', maxWidth: '100px'},
        {label: "Cargo", key: "Cargo", sortable: true, width: '60px'},
        {label: "Estado", key: "Estado", sortable: true, width: '100px', maxWidth: '100px'},
        {label: "Detalles", key: "Detalles", sortable: false, width: '60px'},
        {label: "Opciones", key: "Id", sortable: false, width: '250px', maxWidth: '270px'},
      ],
      EmpresasLista: [],
      EmpresaSeleccionada: null,
      TipoFiltro: "All",
    };
  },
  async created() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
  },
  async mounted() {
    this.$isLoading(true);
    await this.obtenerListaCausalRetiro();
    await this.getData();
    
    this.$isLoading(false);
  },
  methods: {
    async GetNPerFechaIngRetByPersonaId(PersonaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NPerFechaIngRet/GetNPerFechaIngRetByPersonaId/" + PersonaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.NPerFechaIngRet = res;
        } else {
          this.NPerFechaIngRet = [];
        }
      } catch (error) {
        this.NPerFechaIngRet = [];
        console.log("err", error);
      }
    },
    async obtenerListaCausalRetiro() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PerCausalRetiro/GetPerCausalRetiro",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaCausalRetiro = res;
        } else {
          this.listaCausalRetiro = [];
        }
      } catch (error) {
        this.listaCausalRetiro = [];
        console.log("err", error);
      }
    },
    validarYEnviarFormulario() {
      // Usamos el método nativo reportValidity para validar el formulario
      const form = this.$refs.formIngresoRetiro;
      if (form.reportValidity()) {
        // Si el formulario es válido, llamamos a la función de envío
        this.guardarIngresoRetiro();
      }
    },
    async guardarIngresoRetiro() {
      try {
        let dataIngresoRetiro = {
          FechaEvento: this.$moment(new Date()).tz("America/Bogota").format("YYYY-MM-DDTHH:mm"),
          Fecha: this.$moment(this.dataIngresoRetiro.fecha).tz("America/Bogota").format("YYYY-MM-DDTHH:mm"),
          PerCausalRetiroId: this.selected,
          PersonaId: this.infoForPersonData.personaId,
          Tipo: this.infoForPersonData.fechaRetiro == null ? "Retiro" : "Ingreso",
        };
        await this.$store.dispatch("hl_post", {
          path: "NPerFechaIngRet/CreateNPerFechaIngRet",
          data: dataIngresoRetiro,
        });
        this.$bvModal.hide("modal-retiro");
        await this.getData();
        await this.getPersonaUbicacion();

        this.$toast.success("Registro almacenado correctamente", {
          position: "top-right",
          timeout: 2984,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        alert(error);
      }
    },
    gotoRegistroNovedades(it) {
      console.log(it);
      if (it.Salario == null || it.Salario == 0) {
        Swal.fire("Error", "Empleado no tiene salario asignado", "Error");
      } else {
        let nominaPath = this.$route.path.includes("nomina");
        if (nominaPath) {
          this.$router.push({
            name: "PersonaNovedades",
            params: {Id: it.Id},
          });
        } else {
          this.$route.params.liquidacionId == undefined ? this.$router.push("RegistroNovedades/" + it.Id) : this.$router.push("RegistroNovedades/" + it.Id + "/" + this.$route.params.liquidacionId);
        }
      }
    },
    async infoForPerson(data) {
      this.infoForPersonData = {};
      this.infoForPersonData.nombres = data.Nombres;
      this.infoForPersonData.segundoNombre = data.SegundoNombre;
      this.infoForPersonData.apellidos = data.Apellidos;
      this.infoForPersonData.segundoApellido = data.SegundoApellido;
      this.infoForPersonData.tipoDoc = data.TipoDocumentoDescripcion;
      this.infoForPersonData.doc = data.NumeroDocumento;
      this.infoForPersonData.estadoCivil = data.EstadoCivilDescripcion;
      this.infoForPersonData.direccion = data.Direccion;
      this.infoForPersonData.municipio = data.MunicipioDescripcion;
      this.infoForPersonData.telFijo = data.TelefonoFijo;
      this.infoForPersonData.telMovil = data.TelefonoMovil;
      this.infoForPersonData.correo = data.Correo;
      this.infoForPersonData.rol = data.RolDescripcion;
      this.infoForPersonData.empresa = data.EmpresaNombre;
      this.infoForPersonData.fechaContratacion = data.FechaContratacion;
      this.infoForPersonData.fechaRetiro = data.FechaRetiro;
      this.infoForPersonData.personaId = data.Id;
      this.infoForPersonData.sucursal = data.Sucursal;
      this.infoForPersonData.area = data.Area;

      await this.GetNPerFechaIngRetByPersonaId(data.id);
    },
    async TogglePersonaEstado(id) {
      Swal.fire({
        title: "Seguro que quiere cambiar el estado?",
        text: "Puede cambiar el estado las veces que lo requiera!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "rgb(242, 19, 67)",
        confirmButtonColor: "#1A5CFF",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Persona/TogglePersonaEstado/" + id,
          });
          await this.getData();

          Swal.fire("Listo!", "Se ha cambiado el estado correctamente.", "success");
        }
      });
      this.$isLoading(false);
    },
    async getPersonaUbicacion() {
      try {
        //   console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/List/" + this.empresaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaPersonaUbicacion = res;
        } else {
          let nominaPath = this.$route.path.includes("nomina");
          if (nominaPath) {
            this.$router.push({
              name: "PersonaNovedades",
              params: {Id: res.id},
            });
          } else {
            this.$route.params.liquidacionId == undefined ? this.$router.push("RegistroNovedades/" + res.id) : this.$router.push("RegistroNovedades/" + res.id + "/" + this.$route.params.liquidacionId);
          }
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },
    obtenerUbicacionPersona(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      if (io > 0) {
        let result = this.listaPersonaUbicacion.indexOf(io);
        console.log("cargo ", result);
      }
      return io ? io.cargo.nombre : "";
    },
    getUbicacionPersonaDatallesModal(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      // console.log("cargo detalle ", io);
      this.infoForPersonData.sucursal = io.sucursal.descripcion;
      this.infoForPersonData.area = io.area.descripcion;
    },
    // async getPersonaUbicacion() {
    //   try {
    //     //   console.log("get empresas...");
    //     let res = await this.$store.dispatch("hl_get", {
    //       path: "PersonaUbicacion/List/" + this.empresaId,
    //     });
    //     console.log("post empresas...", res);
    //     if (res.length > 0) {
    //       this.listaPersonaUbicacion = res;
    //     } else {
    //       this.listaPersonaUbicacion = [];
    //     }
    //   } catch (error) {
    //     this.listaPersonaUbicacion = [];
    //     console.log("err", error);
    //   }
    // },
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.empresaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      if (this.persona.rol.nivel !== 4) this.EmpresaSeleccionada = null;

      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `Persona/ObtenerCantidadDatos/${this.empresaId}?filtro=${params.filtro}&EmpresaConsultar=${this.EmpresaSeleccionada}&estado=${this.TipoFiltro}`
        }),
        this.$store.dispatch('hl_post', {
          path: `Persona/ObtenerPersonasLotes/${this.empresaId}?EmpresaConsultar=${this.EmpresaSeleccionada}&estado=${this.TipoFiltro}`,
          data: params
        }),
        this.$store.dispatch('hl_get', {
          path: `Persona/ObtenerCantidadPersonasEstado/${this.empresaId}?filtro=${params.filtro}&EmpresaConsultar=${this.EmpresaSeleccionada}`
        })
      ]).then(([cantidadDatos, Datos, Estados]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
        }
        if (Estados.respuesta) {
          this.dataCards[0].value = Estados.data.All;
          this.dataCards[1].value = Estados.data.Activo;
          this.dataCards[2].value = Estados.data.Inactivo;
        }
      });
      return {total, data};
    },
    async getData() {
      this.$store.dispatch('hl_get', {
        path: 'Empresa/ListEmpresas'
      }).then(response => {
        if (response.respuesta) {
          this.EmpresasLista = response.data;
        }
      })
    }
  }
}
</script>

<style>

/* tooltip  */
.font_tooltip {
  font-size: 14px;
  font-style: italic;
}
</style>
